import React from 'react'
import { Link } from 'gatsby'

const ReloadLink = ({ to, children, ...props }) => {
  const handleClick = (event) => {
    // Prevent the default behavior of the link
    event.preventDefault()
    
    // Reload the window
    window.location.href = to
  }

  return (
    <Link className={props.className} to={to} onClick={handleClick} {...props}>
      {children}
    </Link>
  )
}

export default ReloadLink